import * as eventsApi from "../../api/eventsApi";
//actions

export const GET_EVENTSCLAIM_REQUEST = "GET_EVENTSCLAIM_REQUEST";
export const GET_EVENTSCLAIM_SUCCESS = "GET_EVENTSCLAIM_SUCCESS";
export const GET_EVENTSCLAIM_FAILURE = "GET_EVENTSCLAIM_FAILURE";

export const claimEventOffer = (claimData) => (dispatch) => {
  dispatch({ type: GET_EVENTSCLAIM_REQUEST });
  return eventsApi
    .claimEventOffer(claimData)
    .then((response) => {
      dispatch({ type: GET_EVENTSCLAIM_SUCCESS, progress: response });
      window.location.href = response;
      return response;
    })
    .catch((err) => {
      dispatch({ type: GET_EVENTSCLAIM_FAILURE, err });
      throw err;
    });
};

export const FETCH_EVENTS_REQUEST = "FETCH_EVENTS_REQUEST";
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
export const FETCH_EVENTS_FAILURE = "FETCH_EVENTS_FAILURE";

export const fetchEvents = (partnerListingId) => (dispatch, getState) => {
  dispatch({ type: FETCH_EVENTS_REQUEST });
  return eventsApi
    .fetchEvents(partnerListingId)
    .then((resp) => {
      dispatch({ type: FETCH_EVENTS_SUCCESS, events: resp });
    })
    .catch((err) => {
      dispatch({ type: FETCH_EVENTS_FAILURE, err });
      throw err;
    });
};

export const STATE_KEY = "events";

export const DEFAULT_STATE = {
  events: null,
};

const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_EVENTS_SUCCESS:
      return { ...state, events: action.events };
    case GET_EVENTSCLAIM_SUCCESS:
      return { ...state, response: action };
    default:
      return { ...state };
  }
};

export default reducer;

//selectors

export const selectEvents = (state) => state.events.events;
