import users, { STATE_KEY as USERS_STATE_KEY } from "./modules/users";
import modal, { STATE_KEY as MODAL_STATE_KEY } from "./modules/modal";
import authorization, {
  STATE_KEY as AUTHORIZATION_STATE_KEY,
} from "./modules/authorization";
import verification, {
  STATE_KEY as VERIFICATION_STATE_KEY,
} from "./modules/verification";
import processing, {
  STATE_KEY as PROCESSING_STATE_KEY,
} from "./modules/processing";
import errors, { STATE_KEY as ERRORS_STATE_KEY } from "./modules/errors";
import { routerReducer } from "react-router-redux";
import { reducer as formReducer } from "redux-form";
import tickets, { STATE_KEY as TICKETS_STATE_KEY } from "./modules/tickets";
import shopify, { STATE_KEY as SHOPIFY_STATE_KEY } from "./modules/shopify";
import notifications, {
  STATE_KEY as NOTIFICATIONS_STATE_KEY,
} from "./modules/notifications";
import offers, { STATE_KEY as OFFERS_STATE_KEY } from './modules/offers';
import events, { STATE_KEY as EVENTS_STATE_KEY } from './modules/events';

const reducers = {
  [USERS_STATE_KEY]: users,
  [MODAL_STATE_KEY]: modal,
  [AUTHORIZATION_STATE_KEY]: authorization,
  [VERIFICATION_STATE_KEY]: verification,
  [PROCESSING_STATE_KEY]: processing,
  [ERRORS_STATE_KEY]: errors,
  [TICKETS_STATE_KEY]: tickets,
  [SHOPIFY_STATE_KEY]: shopify,
  [NOTIFICATIONS_STATE_KEY]: notifications,
  [OFFERS_STATE_KEY]: offers,
  [EVENTS_STATE_KEY]: events,
  routing: routerReducer,
  form: formReducer,
};

export default reducers;
