import { getJson } from './baseApi'
import { getOffersDomain } from '../setup/configuration'

export const claimEventOffer = (claimData) => {
  return getJson(
    `/events/claim?offerId=${claimData.OfferId}&userId=${claimData.UserId}&state=${claimData.State}`
  );
};
export const fetchEvents = (partnerListingId) => {
	return getJson(getOffersDomain() + `/events/search/${partnerListingId}`, { timeout: 180000, useApiDomain: false })
}