
import React from "react";
import { Grid } from "@mui/material";
import { DialogContent } from "@mui/material";
import Button from "../Button";
import { hideModal } from "../../redux/actions";
import { getIconUrls } from "../../setup/configuration";
import ModalContainer from "./ModalContainer";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";

const fontFamily = ["Geomanist Medium", "Helvetica", "sans-serif"].join(",")

const classes = {	
    section: {
        marginTop:'16px'
    },
    groupName: {
        fontFamily: fontFamily,
        fontSize: '16px',
        color: '#323440',
    },
    groupAffiliations: {
        fontFamily: 'geomanist-regular, Helvetica, sans-serif',
        fontSize: '14px',
        color: '#616161',
        lineHeight:'18px'
    },
    description: {
    },
    primaryButton: {
		border: 'none',
		fontFamily: fontFamily,
		backgroundColor: '#2A2D36',
		fontWeight: '400',
		letterSpacing: '.2px',
		'&:hover': {
			backgroundColor: '#16171B',
			border: 'none',
		},
		height: '45px',
		borderRadius:'2px',
        margin: '20px auto 0',
        padding:'6px 48px',
        width: '65%',
	},
    modal: {
        width: '440px',
        maxWidth: '440px',
        padding: '20px',
        fontSize: '18px',
    },
    dialogContent: {
        padding: '0',
    }
}

const size = 67

export const EligibleGroupsModal = ({ offer, hideModal }) => {
    let iconUrls = getIconUrls()
    return (
        <ModalContainer title="This offer is available to the following groups" style={classes.modal} maxWidth="440px" padding={isMobile ? '20px' : '40px'}>
            <div>
                <DialogContent sx={classes.dialogContent}> 
                    {
                        offer.verification.eligibleGroups.some(icon => icon === 'Military' || icon === 'MilitaryFamily') && 
                        <Grid container alignItems='flex-start' style={classes.section} >
                            <Grid item xs={3}>
                                <img src={ iconUrls.military } style={{ width: size, height: size }} />
                            </Grid>
                            <Grid item xs={9} style={classes.description}>
                                <div style={classes.groupName}>U.S. Military</div>
                                <div style={classes.groupAffiliations}>
                                Includes active duty, reservists, veterans and military family members.
                                </div>
                            </Grid>
                        </Grid>
                    }
                    {
                        offer.verification.eligibleGroups.some(icon => icon === 'Police') && 
                        <Grid container alignItems='flex-start' style={classes.section} >
                            <Grid item xs={3}>
                                <img src={ iconUrls.police } style={{ width: size, height: size }} />
                            </Grid>
                            <Grid item xs={9}>
                                <div style={classes.groupName}>Law Enforcement</div>
                                <div style={classes.groupAffiliations}>
                                    Includes U.S. federal, state and local law enforcement officials.
                                </div>
                            </Grid>
                        </Grid>
                    }
                    {
                        offer.verification.eligibleGroups.some(icon => icon === 'FirstResponder') &&
                        <Grid container alignItems='flex-start' style={classes.section} >
                            <Grid item xs={3}>
                                <img src={ iconUrls.firstResponder } style={{ width: size, height: size }} />
                            </Grid>
                            <Grid item xs={9}>
                            <div style={classes.groupName}>Fire</div>
                                <div style={classes.groupAffiliations}>
                                Includes U.S. federal, state and local, full-time and volunteer firefighters.
                                </div>
                            </Grid>
                        </Grid>
                    }
                    {
                        offer.verification.eligibleGroups.some(icon => icon === 'Medical') &&
                        <Grid container alignItems='flex-start' style={classes.section} >
                            <Grid item xs={3}>
                                <img src={ iconUrls.medical } style={{ width: size, height: size }} />
                            </Grid>
                            <Grid item xs={9}>
                            <div style={classes.groupName}>Medical Personnel</div>
                                <div style={classes.groupAffiliations}>
                                Includes EMTs, paramedics, nurses, hospital physicians and staff.
                                </div>
                            </Grid>
                        </Grid>
                    }
                    {
                        offer.verification.eligibleGroups.some(icon => icon === 'Government') &&
                        <Grid container alignItems='center'  style={classes.section} >
                            <Grid item xs={3}>
                                <img src={ iconUrls.government } style={{ width: size, height: size }} />
                            </Grid>
                            <Grid item xs={9}>
                                <div style={classes.groupName}>U.S. Government Employees</div>
                                <div style={classes.groupAffiliations}>
                                Includes federal, state and local government employees.
                                </div>
                            </Grid>
                        </Grid>
                    }
                    {
                        offer.verification.eligibleGroups.some(icon => icon === 'Education') &&
                        <Grid container alignItems='center'  style={classes.section} >
                            <Grid item xs={3}>
                                <img src={ iconUrls.education } style={{ width: size, height: size }} />
                            </Grid>
                            <Grid item xs={9}>
                                <div style={classes.groupName}>Educators</div>
                                <div style={classes.groupAffiliations}>
                                    Includes public K-12, College / University teachers, faculty, administrators and staff.
                                </div>
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
                <Grid container justify='center'>
                    <Button children="Roger that!" style={classes.primaryButton} onClick={hideModal} />
                </Grid>
            </div>
        </ModalContainer>
    )
}

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal())
})

export default connect(null, mapDispatchToProps)(EligibleGroupsModal)