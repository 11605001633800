import { configureStore } from "@reduxjs/toolkit";
import * as schema from "../api/schema";
import { history as createBrowserHistory } from "../history";
import monitorReducersEnhancer from "./enhancer/monitorReducer";
import loggerMiddleware from "../middleware/LoggerMiddleware";
import rootReducer from "./reducer";
import errorMiddleware from "../middleware/ErrorMiddleware";
import googleAnalyticsMiddleware from "../middleware/GoogleAnalyticsMiddleware";
import * as session from "./verify-session";

const isProduction = process.env.NODE_ENV === "production";

const resolve = (obj) => new Promise((res) => res(obj));
const reject = (err) => new Promise((res, rej) => rej(err));

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      const middleware = getDefaultMiddleware({
        thunk: {
          extraArgument: {
            session,
            resolve,
            reject,
            schema,
            history: createBrowserHistory,
          },
        },
        immutableCheck: false,
        serializableCheck: false,
      }).concat(
        errorMiddleware,
        googleAnalyticsMiddleware
      );
      if (!isProduction) {
        middleware.push(loggerMiddleware);
      }
      return middleware;
    },
    preloadedState,
    // enhancers: [monitorReducersEnhancer],
    devTools: !isProduction,
  });

  if (isProduction && module.hot) {
    module.hot.accept("./reducer", () => store.replaceReducer(rootReducer));
  }

  return store;
}
