import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

const styles = {
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'@media (max-width: 574px)': {
			backgroundColor: props => props.settings.mobileBackgroundColor,
		},
		'@media (min-width: 575px)': {
			position: 'absolute',
			top: '0px',
			right: '0px',
			left: '0px',
			padding: '0px 30px',
			backgroundColor: '#fff',
			color: '#222',
			boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		},
	},
	logo: {
		maxWidth: '80%',
		maxHeight: props => `${props.settings.logoHeight + 30}px`,
		padding: '15px 0'
	},
}

export const CoBrandedHeader = ({ classes, settings, listingName }) => (
	<div className={ classes.header }>
		<img src={ settings.logoImageUrl } alt={ listingName } className={ classes.logo } />
	</div>
)

CoBrandedHeader.propTypes = {
	classes: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	listingName: PropTypes.string.isRequired,
}

export default injectSheet(styles)(CoBrandedHeader)