
import React from "react";
import { Box, Grid } from "@mui/material";
import { DialogContent } from "@mui/material";
import Button from "../Button";
import { hideModal } from "../../redux/actions";
import ModalContainer from "./ModalContainer";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { compose } from "recompose";
import injectSheet from "react-jss/lib/injectSheet";
import { QRCodeSVG } from "qrcode.react";

const styles = {	
    qrCode: {
        width: "70%",
        fontWeight: "600",
        textAlign: 'center',
      },
      discount: {
        backgroundColor: "#ECF0F1",
        height: '60px'
      },
      dismiss: {
        fontFamily: '"Geomanist Medium", Helvetica, sans-serif',
        fontSize: "14px",
        color: "#0E5777",
        fontWeight: "500",
        textAlign: 'center',
        "&:hover": { 
          textDecoration: "underline",
          cursor: "pointer"
        }
      },
      title: {
        fontFamily: '"Geomanist Medium", Helvetica, sans-serif',
        fontSize: '24px',
        fontWeight: '400',
        textAlign: 'center',
      }
}

export const QRCodeModal = ({ qrCode, hideModal, classes }) => {
    return (
        <ModalContainer padding={'15px'} maxWidth="380px">
            <div>
                <Box className={classes.title}>Checking out in-store?</Box>
                <DialogContent sx={{padding: '20px 15px'}}>
                    <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Grid row style={{marginBottom: '20px'}}>Scan code at register</Grid>
                        <Grid row justifyContent={'center'} alignItems={'center'}>
                            <Box>
                                <QRCodeSVG value={qrCode} height={"200px"} width={"200px"}/>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Grid container style={{marginBottom: '20px'}} justifyContent={'center'} alignItems={'center'}>
                    <div className={classes.dismiss} onClick={hideModal}>Dismiss</div>
                </Grid>
            </div>
        </ModalContainer>
    )
}

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal())
})

const enhance = compose(
    connect(null, mapDispatchToProps),
    injectSheet(styles)
  );

export default enhance(QRCodeModal)