
import React from "react";
import { Box, Grid } from "@mui/material";
import { DialogContent } from "@mui/material";
import { hideModal } from "../../redux/actions";
import ModalContainer from "./ModalContainer";
import { connect } from "react-redux";
import { compose } from "recompose";
import injectSheet from "react-jss/lib/injectSheet";

const styles = {	
    code: {
        width: "70%",
        fontWeight: "600",
        textAlign: 'center',
      },
      discount: {
        backgroundColor: "#ECF0F1",
        height: '60px',
        marginBottom: '20px'
      },
      dismiss: {
        fontFamily: '"Geomanist Medium", Helvetica, sans-serif',
        fontSize: "14px",
        color: "#0E5777",
        fontWeight: "500",
        textAlign: 'center',
        "&:hover": { 
          textDecoration: "underline",
          cursor: "pointer"
        }
      },
      title: {
        fontFamily: '"Geomanist Medium", Helvetica, sans-serif',
        fontSize: '24px',
        fontWeight: '400',
        paddingLeft: '15px'
      },
      link: {
        color: '#0E5777',
        textDecoration: 'none',
        "&:hover": { 
            textDecoration: "underline",
            cursor: "pointer"
          }
      }
}

export const CodeNotWorkingModal = ({ code, hideModal, classes, url }) => {
    return (
        <ModalContainer padding={'15px'} maxWidth="380px">
            <div>
                <Box className={classes.title}>QR code not working?</Box>
                <DialogContent sx={{padding: '20px 15px'}}>
                    <Grid style={{marginBottom: '20px'}}>If the QR code doesn’t work, show the code below to the sales associate at the register</Grid>
                    <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'}
                        className={classes.discount}
                        tabIndex="0"
                    >
                        <Grid className={classes.code}>{code}</Grid>
                    </Grid>
                    <Grid>
                        This discount code also works online at <a href={url} className={classes.link} target="_">{url?.replace(/^https?:\/\//, "")}</a>.
                    </Grid>
                </DialogContent>
                <Grid sx={{marginBottom: '15px'}} container justifyContent={'center'} alignItems={'center'}>
                    <div className={classes.dismiss} onClick={hideModal}>Dismiss</div>
                </Grid>
            </div>
        </ModalContainer>
    )
}

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal())
})

const enhance = compose(
    connect(null, mapDispatchToProps),
    injectSheet(styles)
  );

export default enhance(CodeNotWorkingModal)